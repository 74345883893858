<template>
  <div class="login">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white lg:w-1/3 p-8 md:p-12 rounded-lg shadow-xl">
        <div>
          <img class="logo w-40 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')" />
        </div>
        <div class="mt-8">
          <main>
            <section class="text-center">
              <h3 class="font-bold text-2xl">{{ $t("change_password_modal.change_password") }}</h3>
              <p class="text-gray-600 pt-2 text-lg">{{ $t("change_password_modal.change_password_title") }}</p>
            </section>

            <section class="mt-8">
              <ValidationObserver ref="formSetPassword">
                <div class="flex flex-col">
                  <div>
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.old_password") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider name="Gammalt lösenord" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="password" v-model="oldPassword" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.new_password") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider name="Nytt lösenord" rules="required" v-slot="{ classes, errors }" vid="password">
                      <div class="input-validate" :class="classes">
                        <input type="password" v-model="password" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.confirm_password") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider name="Bekräfta lösenord" rules="required|confirmed:password" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="password" v-model="confirmPassword" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" data-vv-as="password" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="clearfix mt-5">
                    <div class="float-right">
                      <button class="btn-blue px-2 py-3 text-sm mt-5" @click.prevent="changePassword()">
                        {{ $t("change_password_modal.change_password") }}
                        <BaseIcon icon="key" class="ml-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </section>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordFirstLogin",
  title() {
    return `Ändra lösenord | SecurCloud`;
  },
  data() {
    return {
      user: this.$store.state.user,
      isLoading: false,
      oldPassword: "",
      password: "",
      confirmPassword: "",
    };
  },

  methods: {
    async changePassword() {
      this.$refs.formSetPassword.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          let data = {
            old_password: this.oldPassword,
            password: this.password,
          };

          this.isLoading = true;
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/change_password`, data);
          this.isLoading = false;

          if (response.data.error) this.handleError(null, response.data.message);

          if (response.data.success) {
            this.handleSuccess("Lösenordsändring lyckades");
            this.resetForm();
            this.skip();
          }
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },

    skip() {
      if (this.user.role == "superuser") this.$router.push({ name: "dashboard", params: { id: "-" } });
      else if (this.user.role == "user") this.$router.push({ name: "dashboard", params: { id: this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-" } });
      else if (this.user.role == "securdesktop") this.$router.push({ name: "dashboard", params: { id: this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-" } });
      else if (this.user.role == "alarm_center") this.$router.push({ name: "alarm_center_dashboard", params: { id: this.user.alarm_center_id } });
      else if (this.user.role == "reseller") this.$router.push({ name: "reseller_dashboard", params: { id: this.user.reseller_id } });
      else if (this.user.role == "seller") this.$router.push({ name: "seller_dashboard", params: { id: this.user.seller_id } });
      else if (this.user.role == "tech") this.$router.push({ name: "tech_dashboard", params: { id: this.user.tech_id } });
      else if (this.user.role == "admin") this.$router.push("/admin").catch(() => {});
    },

    resetForm() {
      this.oldPassword = "";
      this.password = "";
      this.confirmPassword = "";
      this.$refs.formSetPassword.reset();
    },
  },
};
</script>
